@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.calendarTileContainer {
    flex: 1;
    padding: 10px;
    padding-top: 0px;
    padding-right: 5px;
    padding-bottom: 20px;
    margin-right: 1%;
    background-color: $WHITE;
    border: 1px solid $GRAY_4_050;
    border-radius: 3px;
}
.weekText {
    margin: 0px;
    margin-top: 10px;
    font-family: $OPENSANS_REGULAR;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: $BLACK;
}
.shiftInput {
    width: 40px;
    height: 30px;
    padding-left: 5px;
    margin-top: 5px;
    font-size: 12px;
    border: 1px solid $BLUE_050;
    border-radius: 3px;
    box-shadow: inset 0px 0px 5px $BLACK_SHADOW_INPUT;
}

.shiftInput:disabled {
    background-color: $GRAY_5_075;
    border: 1px solid $GRAY_4_050;
    box-shadow: inset 0px 0px 0px $BLACK_SHADOW_INPUT;
}

.shiftInputChanged {
    background: $YELLOW_025;
    border: 1px solid $GOLDEN_YELLOW_100;
    border-radius: 3px;
    box-shadow: inset 0px 0px 5px $BLACK_SHADOW_INPUT;
}

.inputHeading {
    margin: 0px;
    font-family: $OPENSANS_REGULAR;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    color: $GRAY_1_075;
}

.disabledBgColor {
    background-color: $GRAY_5_075;
}

.contentBgColor {
    background-color: $SKY_BLUE_025;
}

.iconDiv {
    margin-top: 6px;
    margin-right: 10px;
}

.resetIconDiv {
    margin-top: 6px;
    margin-right: 5px;
    cursor: pointer;
}

.shiftDiv {
    flex: 1;
}
.shiftRightDiv {
    margin-right: 2px;
}
