@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.pill {
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.pill__label {
  padding: 4px;
  margin-right: 10px;
  font-family: $OPENSANS_REGULAR;
  font-size: 10px;
  line-height: 26px;
  background-color: $GRAY_5_050;
  border: 2px solid $GRAY_4_050;
  border-radius: 2px;
}
