@import './scss-constants/colors.scss';
@import './scss-constants/fonts.scss';

.titleSection {
  margin-bottom: 20px;
  font-family: Barlow-Bold;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  color: $BLUE_100;
}


.calendarField {
  padding: 14px 10px;
  overflow: hidden;
  font-family: $OPENSANS_REGULAR;
  font-size: 14px !important;
  color: $BLACK;
  cursor: pointer;
  background: $GRAY_5_100;
  background-image: url(../assets/images/icon_calendar.png);
  background-repeat: no-repeat;
  background-position: center right 10px;
  border-radius: 5px;
}

.more-button {
  font-family: $BARLOW_BOLD;
  font-size: 20px;
  line-height: 24px;
  color: $LIGHT_BLUE_100;
}
.headerTitle{
  margin-left: 20px;
  font-family:$BARLOW_SEMI_BOLD;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  color: #003366;
}
.eIContainer{
  width: 100%;
  /* margin-top: 124px; */
}

.iFrameHeight{
  height: 100%;
  height: -moz-available;          /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  height: stretch;
  -webkit-overflow-scrolling: touch;
}
.contentFieldText{
  font-family: OpenSans-Regular;
  font-size: 14px;
  font-weight: 400;
  color: #53626A;
}

.attachementNumber{
  
}

.headerSticky{
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  z-index: 1000;
  padding-bottom: 10px;
  background-color: white;
}
.labelText{
  font-family: "OpenSans-SemiBold";
  /* font-family: $OPENSANS_REGULAR; */
  font-size: 12px;
  font-weight: bold;
  color: $BLACK;
}
.infoIcon {
  width: 10px;
  height: 10px;
  vertical-align: baseline;
}

.submitModal__footer {
  flex-wrap: wrap;
  justify-content: flex-end;
}

.woinfo {
  margin-bottom: 1.5rem;
  font-family: $OPENSANS_REGULAR;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
}

.operationContent__title {
  margin-bottom: 5px !important;
  font-family: $OPENSANS_BOLD;
  font-size: 12px !important;
  line-height: 18px;
  color: $BLACK; 
}

.modal__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px 20px;
}

.modal__content {
  padding: 20px;
}

.personnelItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.personnelRow_icon {
  display: flex;
  flex-basis: 62px;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 3px;
}
.navbar_bg{
  padding: 0px !important;
  padding-left: 10px !important;
  background-color: $BLUE_100;
}

.appName {
  display: inline-block;
  margin: 0px ; 
  margin-left: 20px ;
  font-family: $OPENSANS_REGULAR ;
  font-size: 14px ;
  font-weight: 600 ;
  line-height: 21px ;
  color: $WHITE ;

}
.pages_dd {
  padding: 5px;
  margin-right: 10px !important;
  font-family: $OPENSANS_REGULAR ;
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  color: $WHITE !important;
}
.dd_sub_heading{
  margin-left: 15px;
  font-family: $OPENSANS_REGULAR ;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: $GRAY_1_100;
}

.dd_page{
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-family: $OPENSANS_REGULAR ;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: $LIGHT_BLUE_100 !important;
}
.menuWidth{
  top: 30px !important;
  min-width: 13rem !important;
  overflow: hidden !important;
}

.menuWidth:hover{
 overflow: scroll !important;
}

.remove_button_styling{
  background-color: transparent;
  border-color: transparent;
  border-width: 0px;
}

.fixed-header{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.profile-menu-width{
  min-width: 13rem !important;
}
.dropdownItem:active {
  background-color: $GRAY_5_100 !important;
}

.homeLink:hover{
  color: $BLUE_025 !important;
  text-decoration: none;
}

.homeLink {
  padding: 5px;
}

.toggler_icon{
  background-color: $WHITE !important;
}

.rightMenu{
  display: inline-block;
  margin: 0;
  font-family: $OPENSANS_REGULAR ;
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  color:$WHITE !important;
}

.feedback{
  padding: 5px;
  cursor: pointer
}

.feedback:hover{
  color: $BLUE_025 !important;
}

.pages_dd:hover{
  color: $BLUE_025 !important;
}

.breadcrumb__link{
  padding-right: 5px;
  padding-left: 0px;
  font-family: $OPENSANS_REGULAR ;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  color: $LIGHT_BLUE_100 !important;
  cursor: pointer;
}
.breadcrumb__link:hover{
  text-decoration: none;
}
.breadcrumb__container{
  height: 55px;
  padding-top: 15px;
  padding-left: 20px;
  background-color: $WHITE;
}

.durationModal__warning {
  display: block;
  padding-top: 20px;
  font-family: $OPENSANS_REGULAR;
  color: $RED_100;
  text-align: right;
}

.durationModal_select {
  width: 30%;
}

.woinfosWrapper {
  margin-top: 20px;
}
.nameText{
  font-family: $OPENSANS_REGULAR;
  font-size: 14px;
  color: $GRAY_1_075;
  /* padding-left: 10px; */
}