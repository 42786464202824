@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.navbar_bg{
  padding: 7px !important;
  background-color: $BLUE_100;
}

.appName {
  display: inline-block;
  margin: 0px ;
  margin-left: 20px ;
  font-family: $OPENSANS_REGULAR ;
  font-size: 14px ;
  font-weight: 600 ;
  line-height: 21px ;
  color: $WHITE ;

}
.pages_dd {
  padding: 5px;
  margin-right: 10px !important;
  font-family: $OPENSANS_REGULAR ;
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  color: $WHITE !important;
}
.dd_sub_heading{
  margin-left: 15px;
  font-family: $OPENSANS_REGULAR ;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: $GRAY_1_100;
}

.dd_page{
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-family: $OPENSANS_REGULAR ;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: $LIGHT_BLUE_100 !important;
}
.menuWidth{
  top: 30px !important;
  min-width: 13rem !important;
  overflow: hidden !important;
}

.menuWidth:hover{
 overflow: scroll !important;
}

.remove_button_styling{
  background-color: transparent;
  border-color: transparent;
  border-width: 0px;
}

.fixed-header{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.profile-menu-width{
  min-width: 13rem !important;
}

.homeLink:hover,.homeLink:active{
  color: $GRAY_5_100 !important;
  text-decoration: none;
}

.homeLink {
  padding: 5px;
}

.toggler_icon{
  background-color: $WHITE !important;
}

.rightMenu{
  display: inline-block;
  margin: 0;
  font-family: $OPENSANS_REGULAR ;
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  color:$WHITE !important;
}

.feedback{
  padding: 5px;
  cursor: pointer
}

.feedback:hover{
  color: $GRAY_4_100 !important;
}

.pages_dd:hover{
  color:$GRAY_4_100 !important;
}
.dd_page:active{
  background-color:$GRAY_2_025 !important;
}

.breadcrumb__link{
  padding-right: 5px;
  padding-left: 0px;
  font-family: $OPENSANS_REGULAR ;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  color: $LIGHT_BLUE_100 !important;
  cursor: pointer;
}
.breadcrumb__link:hover{
  text-decoration: none;
}
.breadcrumb__container{
  height: 55px;
  padding-top: 15px;
  padding-left: 20px;
  background-color: $WHITE;
}
.breadcrumb__last{
  margin-right: 5px;
  font-family: $OPENSANS_REGULAR ;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: $GRAY_1_100;
}

.breadcrumb__disable{
  margin-right: 5px;
  font-family: "OpenSans-Regular" ;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #7F99B2;
}

.searchWorkOrder{
  display: flex;
  margin-right: 20px;
}

@media (max-width: 991px) {
  .w-md-100 {
     display: inline-flex;
     align-items: center;
     justify-content: space-between;
     width:100% !important;
  }

}

.search_order::placeholder{
  font-family: $OPENSANS_ITALIC;
  font-size: 14px;
  line-height: 14px;
  color:$BLUE_075;
}

.search_order{
  box-sizing: border-box;
  height: 30px;
  padding-left: 10px;
  font-family: $OPENSANS_REGULAR;
  font-size: 14px;
  line-height: 14px;
  color: $GRAY_1_100;
  background: $GRAY_5_025;
  border: 1px solid $BLUE_050;
  border-radius: 3px 0px 0px 3px;
}

.enableWoButton{
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: #FFCC00;
  border-radius: 0px 3px 3px 0px;
}

.disableWoButton{
  width: 30px;
  height: 30px;
  background: $GRAY_5_075;
  background-color: #FFCC00;
  /* border: 1px solid $GRAY_4_050; */
  border-radius: 0px 3px 3px 0px;
}
.notificationContent {
  background-color: #c8cdd2;
  border: 1px solid #7F99B2;
  border-radius: 10px;
}
.notificationTitle {
  font-family: $OPENSANS_REGULAR;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  color: #192D38;
}
.notificationTime {
  font-family: $OPENSANS_REGULAR;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #53626A;
}
.filterListDiv{
  position:absolute;
  top: 47px;
  right: 80px;
  z-index:2;
  width:475px;
  height: 423px;
  background: #FFFFFF;
  border-radius: 3px;
  box-shadow: 0px 0px 30px rgba(73, 89, 97, 0.8);
}
.notificationHeader {
  height: 37px;
  padding: 10px;
  font-family: $OPENSANS_REGULAR;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  color: #192D38;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.notificationBody {
  font-family: $OPENSANS_REGULAR;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
}
.noNotificationText {
  font-family: $OPENSANS_REGULAR;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #192D38;
}
.dropdownDivider{
  position:relative;
  z-index:1001;
  border-top: 1px solid rgba(0, 0, 0, 0.175);
}

.linkButton {
  width: 100%;
  color:#0578BE;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

