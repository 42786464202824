@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.labelValueContainer{
    padding: 10px;
}

.labelFieldText{
    font-family: $OPENSANS_REGULAR;
    font-size: 0.75rem;
    color: $GRAY_1_075;
}

.valueText{
    margin-left: 20px;
    font-family: $OPENSANS_REGULAR;
    font-size: 1rem;
    color: $GRAY_1_100;
}