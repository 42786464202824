@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.evenListBgColor {
    background-color: $WHITE;
}

.oddListBgColor {
    background-color: $UI_LIGHT_BLUE;
}

.resultFooter {
    margin-bottom: 100px;
}

.selectView {
    margin-right: 20px;
}

.shortText {
    margin-bottom: 5px !important;
    font-family: $OPENSANS_BOLD;
    font-size: 12px !important;
    line-height: 18px;
    color: $BLACK;
}

.bomListHeading {
    box-sizing: border-box;
    padding: 4px;
    margin: 2px;
    margin-right: 15px;
    font-family: $OPENSANS_SEMI_BOLD;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    color: $GRAY_2_075;
    background-color: $GRAY_5_050;
    border: 1px solid $GRAY_4_050;
    border-radius: 2px;
}

.bomList {
    margin-right: 10px;
    font-family: "OpenSans-Regular";
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    color: #53626A;
}

.listItemContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.pointerCss {
    cursor: pointer;
}

.bomList {
    margin-right: 10px;
    font-family: $OPENSANS_REGULAR;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    color: $GRAY_1_075;
}

.padeLoader {
    height: 350px;
}

.emptyDiv {
    flex-direction: column;
    align-items: center;
    margin-top: 45px;
}

.failureMessage {
    margin-top: 10px;
    font-family: $OPENSANS_REGULAR;
    font-size: 16px;
    color: $UI_BRIGHT_RED_100;
}

.listDiv {
    flex-direction: column;
    align-items: center;
    margin-top: 45px;
}

.instructionText {
    margin-top: 10px;
    font-family: $OPENSANS_REGULAR;
    font-size: 16px;
    color: $GRAY_1_075;
}

.materialText {
    font-family: OpenSans-Regular;
    font-size: 14px;
    color: white;
}

.descriptionText {
    margin: 0;
    margin-bottom: 5px;
    font-family: 'OpenSans-Regular';
    font-size: 12px;
    font-weight: "600";
    color: #192D38
}

.materialText {
    margin: 0px;
    margin-bottom: 5px;
    font-family: 'OpenSans-Bold';
    font-size: 12px;
    font-weight: "600";
    color: #003366;
}

.activePage {
    flex: 1;
    padding: 10px;
    font-family: Barlow-SemiBold;
    font-size: 16px;
    color: white;
    cursor: pointer;
    background-color: #003366;
}

.inActivePage {
    flex: 1;
    padding: 10px;
    font-family: Barlow-SemiBold;
    font-size: 16px;
    color: #53626A;
    cursor:pointer;
    background-color: #F1F5F8;
}

.docListHeading {
    font-family: OpenSans-SemiBold;
    font-size: 14px;
    color: #0578BE;
}

.tabHeadingText {
    margin: 0px;
    text-align: center;
}

.docListId {
    font-family: OpenSans-Regular;
    font-size: 14px;
    color: #53626A;
}
.bomListHeadingGreen{
    box-sizing: border-box;
    padding: 4px;
    margin: 2px;
    margin-right: 15px;
    font-family: "OpenSans-SemiBold";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    color: #568F4B;
    background-color: #E0F0D1;
    border: 1px solid #568F4B;
    border-radius: 2px;
}
.selectedValue{
    display: flex;
    flex: none;
    flex-direction: row;
    flex-grow: 0;
    gap:3px;
    align-items: center;
    justify-content: center;
    order: 0;
    padding: 5px;
    border: 0.833px solid #40668C;
    border-radius: 2.5px;

}
.selectedItem{
    display: flex;
    flex: none;
    flex-grow: 0;
    align-items: center;
    order: 1;
    font-family: 'OpenSans-Regular';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    color: #40668C;
    text-align: center;
}
.linkText {
    font-family: 'OpenSans-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color:#0578BE;
    text-decoration: underline;
}