@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";
@import "../../../constants/global-styles.scss";

.eIContainer{
    width: 100%;
    /* margin-top: 124px; */
}

.tabContainer{
    display: flex;
    flex-direction: row;
    height: 36px;
    /* align-items: flex-end; */
}

.reservationHeader{
    display: flex;
    flex-direction: row;
}

.woAttentionContainer{
    display:flex;
    flex-direction:"row";
    align-items: center;
    margin-right: 20px;
}

.attentionRow{
    flex-direction: row;
}

.failText{
    color: #CC0000;
    text-transform: uppercase;
}

.workOrderContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.woTabView{
    display: flex;
    align-items:center;
    justify-content:center ;
    width:120px;
    height: 35.13px;
    font-size: 14px;
    font-style: OpenSans-SemiBold;
    cursor: pointer;
    border-right: 1px solid #BFC9D0;
    border-radius: 3.5px 0px 0px 3.5px;
}

.addContainer{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    cursor: pointer
}

.saveResButton{
    position: fixed;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 10px;
    background-color: white;
}

.heightFooter{
    height: 70px;
}

.addRow{
    align-items: center;
    justify-content:center;
    width: 40px;
    height: 40px;
}

.ccTabView{
    display: flex;
    align-items: center;
    justify-content: center;
    width:120px;
    height: 35.13px;
    font-size: 14px;
    font-style: OpenSans-SemiBold;
    text-align: center;
    cursor: pointer;
    border-right: 1px solid #BFC9D0;
    // border-radius: 0px 3.5px 3.5px 0px;
}
.wbsTabView{
    display: flex;
    align-items: center;
    justify-content: center;
    width:120px;
    height: 35.13px;
    font-size: 14px;
    font-style: OpenSans-SemiBold;
    text-align: center;
    cursor: pointer;
    border-radius: 0px 3.5px 3.5px 0px;
}
.tabText{
    margin: 0px;
}

.searchContainer  {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.activeTab{
    color:white;
    background-color:  #0578BE;
}

.disabledTab {
  color:#8C969B;
  background-color:  #E0E4E6
}

.headerBreadCrumb{
    margin-left: 20px;
    font-family: Barlow-Light;
    font-size: 24px;
}

.resInsText{   
 margin:0;
 margin-bottom: 10px;
 font-family: OpenSans-Regular;
 font-size: 14px;
 color:#53626A;
}
.wbsTabView{
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 10px; */
    width:120px;
    height: 35.13px;
    padding-right: 30px;
    padding-left: 15px;
    margin-right:10px;
    font-size: 14px;
    font-style: OpenSans-SemiBold;
    /* -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 100%, 90% 0); */
    cursor: pointer;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    
}
.btnText{
    display: flex;
    flex-direction: row;
    gap:10px;
    align-items: center;
    width: calc(100% + 40px);
    height:40px;
    padding-left:10px;
    margin-top: 10px;
    margin-left:-20px;
    
    background: #F1F5F8;
}
.btncolor{
    display:block;
    padding:2px;
    padding-right: 10px;
    padding-left: 10px;
    margin-top:15px;
    color:#53626A;
    background: #F1F5F8;
    border: 1.167px solid #CFD7DC;
    border-radius: 3.5px;
}
.btnbg{
    padding:2px;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 15px;
    background-color: #CC0000;
}
.btnnone{
    display:none;
}
.headerTitle{
    margin-left: 20px;
    font-family: 'Barlow-SemiBold';
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color: #003366;
}
.titleText{
    margin-bottom: 10px;
    font-family: "Barlow-Bold";
    font-size: 24px;
    font-weight: 700;
    color:rgba(0, 51, 102, 1);
}
