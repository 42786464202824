.workCenterTable{
    min-width: 1200px;
    overflow: scroll;
    word-break: break-word;
}

.tableHeader {
    font-family: OpenSans-SemiBold;
    font-weight: 400;
    color: white;
    background-color: #003366;
}

.eInspUrlText{
    padding: 10px;
    font-family: OpenSans-Regular;
    font-size: 14px;
    font-weight: 400;
    color:#0578BE;
}

.tableInput{
    width: inherit;
    padding: 10px;
    padding-left: 10px;
    font-family: OpenSans-Regular;
    font-size: 14px;
    color:#192D38;
    text-transform: uppercase;
    border:none
}

.popover-body{
    padding: 10px !important;
}

.tableText{
    display: inline-block;
    width: inherit;
    padding: 10px;
    padding-left: 10px;
    margin: 0px;
    font-family: OpenSans-Regular;
    font-size: 14px;
    font-weight: 400;
}

.italicDisabled{
    font-family: OpenSans-Italic;
    color: #53626A;
    text-transform: uppercase;
}


.pointer{
    cursor: pointer;
}
.dataDropDownInput{
    width: 90%;
    padding: 10px;
    padding-left: 10px;
    font-family: OpenSans-Regular;
    font-size: 14px;
    color:#192D38;
    border:none
}