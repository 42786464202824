@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";
@import "../../../constants/global-styles.scss";

.operation-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: $OPENSANS_REGULAR;
  font-size: 12px;
  line-height: 18px;
  color: $GRAY_1_075;
}

.buttonsWrapper {
  justify-content: end;
  padding-top: 20px;
  border-top: 1px solid $GRAY_3_025;
}

.breadcrumb-button {
  display: inline;
  padding: 0;
  color: $LIGHT_BLUE_100;
  background: transparent;
  border: none;
}

.breadcrumb-button:disabled {
  color: $GRAY_1_050;
}
.attachmentLength{
  padding: 2px;
  padding-right: 4px;
  padding-left: 4px;
  font-size: 12px;
  border-color: #0578BE;
  border-style: solid;
  border-width: 1px;
  border-radius: 1px 
}