.react-date-picker {
  position: relative;
  display: inline-flex;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-date-picker--disabled {
  color: #BFC9D0;
  background-color: #F1F5F8;
  border: 1px solid #DFE4E7;
}
/* .react-date-picker--enabled{
  border:1px solid green;
  width:calc(100% -23px)
} */
.textColor {
  color: #BFC9D0;
}

.date-picker-container {
  width: 100%;
  padding-right: 5px;
    padding-left: 7px;

  border: 1px solid #7F99B2 !important;
  border-bottom: none !important;
  border-top-left-radius: 4px;
  /* border-radius: 2px; */
  border-top-right-radius: 4px;

  /* box-shadow: 0 4px 4px -2px #0578BE !important;
  box-shadow: inset 0 -4px 4px -2px #0578BE !important; */

}
/* .react-date-picker--closed .react-date-picker--enabled .date-picker-container
{
  border:1px solid black !important;
} */


.date-picker {
  background-color: white;
  border: 1px solid  #7F99B2 !important;
  border-radius:3px;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);
}

 .date-picker-changed {
  margin-bottom:-5px;
  /* background: #FFF5CC; */
  border: 1px solid #7F99B2;
  border-bottom-right-radius:0px;
  border-bottom-left-radius: 0px;
  box-shadow: inset -5px 0px 5px -5px #0578BE, inset 5px 0px 5px -5px #0578BE, inset 0px 5px 5px -5px #0578BE !important;
 
  /* box-shadow:inset 0px 0px 5px 0px #0578BE !important;  */
  /* box-shadow:inset 5px 5px 5px -5px #0578BE, inset 5px 0px 5px -5px #0578BE,inset 0px -5px 5px -5px #0578BE !important; */
  /* box-shadow:inset -5px -5px 5px -5px #0578BE,inset 5px 0px 5px -5px #0578BE,inset 0px -5px 5px -5px #0578BE; */

  
}


.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

.react-date-picker__inputGroup__year:disabled,
.react-date-picker__inputGroup__day:disabled,
.react-date-picker__inputGroup__month:disabled {
  color: #BFC9D0;
}

.react-date-picker__inputGroup {
  box-sizing: content-box;
  /* min-width: calc((4px * 3) + 0.54em * 4 + 0.217em * 2); */
  
  flex-grow: 1;
  /* padding: 0 2px; */
  font-family:"OpenSans-regular" !important;


}

.react-date-picker__inputGroup__divider {
  padding: 0px 0;
  white-space: pre;

}

.react-date-picker__inputGroup__input {
  position: relative;
  box-sizing: content-box;
  width:100%;
  min-width: 0.54em;
  height: 100%;
  padding: 0 0px;
  font: inherit;
  -moz-appearance: textfield;
  background: none;
  border: 0;
 
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  padding-left: calc(1px + 0.54em);
  margin-left: -0.54em;

}

.react-date-picker__button {
  padding-bottom: 0.5rem;
  background: transparent;
  border: 0;
}

.react-date-picker__button:enabled {
  cursor: pointer;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}

.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}

.react-date-picker__button svg {
  display: inherit;
}

.react-date-picker__calendar {
  position: absolute;
  /* top: 100%; */
 
  z-index: 800;
  width: 100%;
  max-width: 100vw;
}
/* .react-date-picker__calendar--open .react-date-picker__calendar--enabled{
  border:1px solid white;
} */

.react-date-picker__calendar--closed {
  display: none;

}
/* .react-date-picker .react-date-picker--closed{
  border:1px solid black !important;
}
.react-date-picker .react-date-picker--open{
  border:1px solid blue !important;
} */

.react-date-picker__calendar .react-calendar {
  padding:5px;
  border-width: thin;
  border-radius: 5px;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next2-button {
  display: none;
}

/* .react-calendar__navigation__prev-button 
  {
    
    background: url(../../../assets/images/right-arrow.png) no-repeat !important;
    width:25px;
    height:44px;
   
     
  } */
.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom:-6px !important;
}

.react-calendar__navigation__label {
  font-family: 'OpenSans-Regular';
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  color: #003366;

}

/* .react-calendar__navigation__next-button {
  background: white url(../../../assets/images/rightarrow.png) no-repeat !important;
  color:white;
  margin-top: 7px !important;
  
}

.react-calendar__navigation__prev-button {
  background: white url(../../../assets/images/left-arrow.png) no-repeat !important;
  color:white;
  margin-top: 7px !important;
  margin-left:15px !important
  
} */

div.react-calendar__month-view__days button {
  border: 0.5px solid #ECF2F6;
  /* border-right:1px solid white;
  border-bottom:none;
   */
}
.react-calendar__month-view__days{
  background-color: #ECF2F6 !important;
  border: 0.5px solid #ECF2F6;
  size:2em;
}

.react-date-picker__inputGroup__input--hasLeadingZero{
  padding-top:0.8px !important;
}
.react-calendar__month-view__days__day {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: #0578BE;
  text-align: center;
 
  background: #FFFFFF !important;
}
.react-calendar__month-view__days__day--weekend {
  color: #0578BE !important;
  /* background:#ECF2F6 !important; */
  border: 0.5px solid #BFC9D0;
  border-radius: 0px;
  
  /* padding: 3px !important; */
}


.react-calendar__tile--active{
 
  color:black !important;
  background-color:rgb(255, 245, 204) !important  ;
}



.react-calendar__month-view__weekdays {
  font-family: 'OpenSans-Regular' !important;
  font-size: 12px;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #53626A;
  text-decoration: none !important;
  border:none !important;
}
abbr[title] {
  /* text-decoration: underline;
  -webkit-text-decoration: underline dotted; */
  text-decoration: none !important;
  /* cursor: help; */
  cursor: pointer !important;
  border-bottom: 0;
}
 /* .react-date-picker--enabled{
  border:1px solid green
} */
.dropdown-divider{
  position:relative;
  z-index:1001;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 0.75em 0.5em;
  /* size: 100px !important; */
  font-size: 18px;
  text-align: center;
  background: none;
}
