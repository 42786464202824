@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.wcContainer {
  width: 100%;
  /* margin-top: 124px; */
  min-width: 832px;
  overflow-x: scroll;
}
.labelField {
  font-family: $OPENSANS_REGULAR;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
}
.woinfo {
  margin-bottom: 1.5rem;
  font-family: $OPENSANS_REGULAR;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
}
.wosearchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 124px);
}
.wosearchText {
  margin-top: 20px;
  font-family: $OPENSANS_REGULAR;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: $GRAY_1_075;
}
.wosearchTextError {
  color: $RED_100;
}

.woconfirmationSection {
  padding-top: 20px;
  margin-bottom: 20px;
}

.customRadio {
  color: $BLACK;
}
.customRadio:hover {
  color: $BLACK;
  text-decoration: none;
}
.customRadio.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.confirmationField {
  flex: 1;
  width: 88%;
  padding: 14px 10px;
  font-family: $OPENSANS_REGULAR;
  font-size: 14px !important;
  color: $BLACK;
  cursor: pointer;
  background: $GRAY_5_100;
  border: 0;
  border-radius: 5px;
}
.error {
  background: $RED_025 !important;
}
.woinfos-wrapper {
  padding-bottom: 0px;
}
.titleSection {
  margin-bottom: 20px;
  font-family: Barlow-Bold;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  color: $BLUE_100;
}
h5 {
  margin-bottom: 0 !important;
  font-family: $OPENSANS_REGULAR;
  font-size: 14px !important;
  color: $GRAY_1_075;
}
select {
  padding: 14px 10px;
  font-family: $OPENSANS_REGULAR;
  font-size: 14px !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: $GRAY_5_100;
  background-image: url(../../../assets/images/dropdown-arrow.png);
  background-repeat: no-repeat;
  background-position: center right 10px;
  border: 0;
  border-radius: 5px;
}

.calendarField {
  padding: 6px 10px;
  overflow: hidden;
  font-family: OpenSans-Regular;
  font-size: 14px !important;
  cursor: pointer;

  background: white;
  border: 1px solid #7F99B2;
  /* background-image: url(../../../assets/images/icon_calendar.png);
  background-repeat: no-repeat;
  background-position: center right 10px; */
  border-radius: 3.5px;
}

.calendar-input {
  background-color: transparent;
  border: none;
}
.clockField {
  padding: 14px 10px;
  padding-top: 6px;
  padding-bottom: 5.2px;
  padding-left: 5px;
  font-family: $OPENSANS_REGULAR;
  font-size: 14px !important;
  color: $BLACK;
  background: $GRAY_5_100;
  background-color: #ffffff;
  background-image: url(../../../assets/images/icon_clock.png);
  background-repeat: no-repeat;
  background-position: center right 10px;
  border: 1px solid #7F99B2;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);
  
}
.clockField:hover {
  color: $BLACK;
  text-decoration: none;
}

.more-button {
  font-family: $BARLOW_BOLD;
  font-size: 20px;
  line-height: 24px;
  color: $LIGHT_BLUE_100;
}

/* Personnel Item */
.wopersonnel-section {
  margin-bottom: 20px;
}

.personnel-row_empty {
  display: flex;
  flex-basis: 62px;
  flex-grow: 0;
}

/* Duration Modal */
.duration-modal_controllers {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: $OPENSANS_BOLD;
  font-size: 12px;
}

.field {
  width: 100%;
  background: $GRAY_5_100;
  border: 0;
  border-radius: 5px;
}
button:focus {
  outline: none !important;
}
.operations-subtitle {
  font-family: Barlow-Bold;
  font-size: 20px;
  color: white;
  background: #003366;
}
.operations-singularwrapper {
  font-size: 12px;
  border-bottom: 1px solid #c1c8cd;
}
.operaions-singulardescription {
  font-family: OpenSans-Bold;
}

/* Search */
.search__wrapper {
  display: flex;
  flex-wrap: nowrap;
  /* position: absolute; */
  /* right: 50%; */
  width: 40%;
  margin-right: auto;
  margin-left: auto;
}
.search__input {
  flex: 1;
  width: 88%;
  padding: 14px 10px;
  margin-right: 10px;
  font-family: $OPENSANS_REGULAR;
  font-size: 14px !important;
  color: $BLACK;
  cursor: pointer;
  background: $GRAY_5_100;
  border: 0;
  border-radius: 5px;
}

/* Modals */


/* End Time Modal */
/* Duration Modal */
.durationModal__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: $OPENSANS_BOLD;
  font-size: 12px;
}

/* Submit Modal */
.submitModal__footer {
  flex-wrap: wrap;
  justify-content: flex-end;
}
