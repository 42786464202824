.formInput{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  padding-left: 8px;
  border: 1px solid #7F99B2;
  border-radius: 4px;
  box-shadow: inset 0 0 5px #7F99B2;
}

.formInputValue{
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  font-weight: "600";
  line-height: 14px;
  color: #192D38;
  border: none;
}

.formInputValue::placeholder{
  font-family: 'OpenSans-Italic';
  font-size: 14px;
  font-weight: "600";
  color: #40668C;
}