.dropdownContainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  padding-left: 8px;
  border: 1px solid #7F99B2;
  border-radius: 4px;
  box-shadow: inset 0 0 5px #7F99B2;
}

.formDropdownValue{
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  font-weight: "600";
  color: #192D38;
}

.formDropdownItalicValue{
  font-family: 'OpenSans-Italic';
  font-size: 14px;
  font-weight: "600";
  color: #40668C;
}

.dataDropdownItem {
  padding: 5px;
  padding-left: 10px;
}
.dataDropdownContent{
  position: absolute;
  z-index: 1000;
  width: -webkit-fill-available;
  max-height: 200px;
  padding-bottom: 5px;
  margin-top: 2px;
  overflow: scroll;
  font-family: OpenSans-Regular;
  font-size: 14px;
  color:#192D38;
  background-color: white;
  border: #0578BE solid;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}