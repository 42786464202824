.dataDropdownContent{
    position: absolute;
    z-index: 1000;
    width: -webkit-fill-available;
    max-height: 200px;
    padding-bottom: 5px;
    margin-top: 2px;
    overflow: scroll;
    font-family: OpenSans-Regular;
    font-size: 14px;
    color:#192D38;
    background-color: white;
    border: #0578BE solid;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.borderContainer{
    border: #0578BE solid;
    border-bottom: none;
}

.confPickerClass {
    width: calc(30% - 7px) !important;
}

.dataDropDownInput{
    width: 90%;
    padding: 10px;
    padding-left: 10px;
    font-family: OpenSans-Regular;
    font-size: 14px;
    color:#192D38;
    border:none
}

.dataDropdownItem:hover{
    background-color: #F2F8FC;
}

.dataDropdownItem{
    padding: 5px;
    padding-left: 10px;
}