@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.selectedAsset{
    margin: 0px;
    margin-bottom: 5px;
    margin-left: 15px;
    font-family: $OPENSANS_REGULAR;
    font-size: 16px;
    line-height: 25px;
    color: $GRAY_1_075;
}

.iconDiv {
    margin-top: 6px;
    margin-right: 30px;
}
.headerStyle{
    width:fit-content;
    padding-left: 5px
  }
  .headerTitle{
    margin-left: 20px;
    font-family: 'Barlow-SemiBold';
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color: #003366;
  }