@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.customIconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  transition: all 300ms;
}
.customIconButton:hover:not(:disabled) {
  transition: all 600ms;
  transition-delay: 300ms;
  transform: scale(1.05);
}
