$BARLOW: 'Barlow-Black';
$BARLOW_BOLD: 'Barlow-Bold';
$BARLOW_REGULAR: 'Barlow-Regular';
$BARLOW_MEDIUM: 'Barlow-Medium';
$BARLOW_SEMI_BOLD: 'Barlow-SemiBold';
$BARLOW_THIN: 'Barlow-Thin';
$OPENSANS: 'OpenSans-Black';
$OPENSANS_BOLD: 'OpenSans-Bold';
$OPENSANS_MEDIUM: 'OpenSans-Medium'; 
$OPENSANS_SEMI_BOLD: 'OpenSans-SemiBold';
$OPENSANS_THIN: 'OpenSans-Thin';
$FUTURA_MEDIUM: 'Futura-Medium';
$OPENSANS_ITALIC: "OpenSans-Italic";
$OPENSANS_REGULAR:'OpenSans-Regular';