@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.ellipseDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
    padding-left: 5px;
    border: 1px solid #53626A;
    border-radius: 3px;
}

.ellipseView{
    margin-top: -3px;
    margin-bottom: 3px;
    font-family: "OpenSans-SemiBold";
    color: #53626A;

}
.popover-arrow::after {
    border-bottom-color: #FFF5CC !important;
}

.popover .popover-arrow::after,.popover .popover-arrow::before {
    left: 2px !important;
}

.popover-body{
    box-shadow: 5px 5px 5px rgb(0 0 0 / 25%) !important;
}
.operationContent__title {
    margin-bottom: 5px !important;
    font-family: $OPENSANS_BOLD;
    font-size: 12px !important;
    line-height: 18px;
    color: #000000;
}