/* Palladium colors */

/* Standards */
$BLACK: #000;
$WHITE: #fff;

/* Neutrals */
$GRAY_1_100: #192d38;
$GRAY_1_075: #53626a;
$GRAY_1_050: #8c969b;
$GRAY_1_025: #c5cacd;

$GRAY_2_100: #495961;
$GRAY_2_075: #778389;
$GRAY_2_050: #a4acb0;
$GRAY_2_025: #d1d5d7;

$GRAY_3_100: #83929b;
$GRAY_3_075: #a2adb4;
$GRAY_3_050: #c1c8cd;
$GRAY_3_025: #e0e4e6;

$GRAY_4_100: #bfc9d0;
$GRAY_4_075: #cfd7dc;
$GRAY_4_050: #dfe4e7;
$GRAY_4_025: #eff1f3;

$GRAY_5_100: #ecf2f6;
$GRAY_5_075: #f1f5f8;
$GRAY_5_050: #f5f8fa;
$GRAY_5_025: #fafcfd;

/* Blues */
$BLUE_100: #036;
$BLUE_075: #40668c;
$BLUE_050: #7f99b2;
$BLUE_025: #bfccd9;

$LIGHT_BLUE_100: #0578be;
$LIGHT_BLUE_075: #449ace;
$LIGHT_BLUE_050: #82bbde;
$LIGHT_BLUE_025: #c0ddef;

$SKY_BLUE_100: #39c;
$SKY_BLUE_075: #66b3d9;
$SKY_BLUE_050: #99cce5;
$SKY_BLUE_025: #cce5f2;

$UI_LIGHT_BLUE: #f2f8fc;

/* Yellows */
$YELLOW_100: #fc0;
$YELLOW_075: #ffdb4d;
$YELLOW_050: #ffeb99;
$YELLOW_025: #fff5cc;

$GOLDEN_YELLOW_100: #e5a812;
$GOLDEN_YELLOW_075: #ecbe4d;
$GOLDEN_YELLOW_050: #f2d388;
$GOLDEN_YELLOW_025: #f8e9c4;

/* Greens */
$GREEN_100: #99cc68;
$GREEN_075: #b3d98e;
$GREEN_050: #cce5b3;
$GREEN_025: #e0f0d1;

$LIGHT_GREEN_100: #31af19;

$UI_DARK_GREEN_100: #1e6a0f;
$UI_BRIGHT_GREEN_100: #1e6a0f;

/* Reds */
$RED_100: #c74a34;
$RED_075: #d88071;
$RED_050: #eaada2;
$RED_025: #ffe2dc;

$UI_DARK_RED_100: #900;
$UI_BRIGHT_RED_100: #c00;

/* Purples */
$PURPLE_100: #874487;
$PURPLE_075: #a573a5;
$PURPLE_050: #c3a1c3;
$PURPLE_025: #e1d0e1;

/* RGB Shadows */
$BLACK_SHADOW: rgba(0, 0, 0, 0.5);
$BLACK_SHADOW_INPUT: rgba(0, 0, 0, 0.25);
$WHITE_SHADOW: rgba(255, 255, 255, 0.7);
