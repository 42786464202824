@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.home-container {
  /* min-height: calc(100% - 42px); */
  width: 100%;
  height: 100%;
  /* margin-top: 124px; */
}

.dashboardPlaceholder {
  flex-basis: 116px;
  flex-shrink: 0;
  background-image: url(../../../assets/images/placeholder_home.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.welcomeContainer {
  align-items: "center";
  padding-bottom: 10px;
  padding-left: 20px;
  margin-top: 56px;
  font-family: $OPENSANS_REGULAR;
  font-size: 18px;
}

.welcomeText {
  margin-left: 10px;
  font-family: $OPENSANS_REGULAR;
  font-size: 14px;
  color: $LIGHT_BLUE_100;
  cursor: pointer;
}

.gdHomeLogo {
  width: 230px;
  height: 230px;
}
.lastUpdatedDateStyle{
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  font-weight: 400;
  color: #003366;
}


