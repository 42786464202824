.formSubheaderContainer{
    display: flex;
    flex-direction: row;   
    align-items: center;
    /* border: 1px solid #CFD7DC; */
    width: 100%;
    padding-top: 4px; 
    padding-bottom: 4px;
    margin-bottom: 3px;
    cursor: pointer;
    background-color: #003366;
}

.formSubHeaderContent{
    padding-right: 10px;
    padding-left: 10px;
    font-family: 'OpenSans-Regular';
    font-size: 14px;
    line-height: 20px;
    color: white;
}

.headerCol{
    display: flex;
    flex-direction: row; 
}

.headerIconDiv{
    margin-right: 10px;
    margin-left: 15px;
}

.arrowSquareDiv{
    align-items: center;
    margin-right: 10px;
    margin-left: 15px;
}

.arrowDashSquareIcon{
  width: 16px;
  height: 16px;
}

@keyframes inAnimation {
    0% {
      visibility: hidden;
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }

  @keyframes outAnimation {
    0% {
      opacity: 1;
    }
    100% {
      visibility: hidden;
      opacity: 0;
    }
  }
