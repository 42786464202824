.transactionData{
    font-family: 'OpenSans-Regular';
    font-size: 12px;
    color:#192D38
}

.sparesTransactionTable{
    min-width: 1100px;
    overflow: scroll;
    word-break: break-word;
}

.sparesTransactionTableHeader {
    font-family: OpenSans-SemiBold;
    font-size: 14px;
    font-weight: 400;
    color: white;
    background-color: #7F99B2;
}