@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.taskButtonStyle {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
    font-family: $OPENSANS_REGULAR;
    font-size: 14px;
    color: $WHITE;
    cursor: pointer;
    background-color: $LIGHT_BLUE_100;
    border-radius: 3px;
}

.taskButtonOutlineStyle {
    color: $LIGHT_BLUE_100;
    background: $WHITE;
    border: solid $LIGHT_BLUE_100 1px;
    border-color: $LIGHT_BLUE_100;
}

.buttonContainer {
    /* padding-left: 10px;
    padding-right: 10px; */
    padding-bottom: 10px;
}
.woText {
    /* margin-top: 15px; */
    font-family: $OPENSANS_REGULAR;
    font-size: 16px;
    font-weight: 600;
}

.buttonDiv {
    width: 320px;
    text-align:center;
    background-color: $WHITE;
    border-radius: 5px;
}

.errorWidth {
    width: 370px;
}
.headerDiv {
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.woText {
    font-family: $OPENSANS_BOLD;
    font-weight: bold;
}

.buttonWidth {
    width: auto;
}

.buttonBottom {
    margin-bottom: 10px;
}
