.taskButtonStyle{
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
    font-family: "OpenSans-Regular";
    font-size: 14px;
    color: white;
    cursor: pointer;
    background-color: #0578BE;
    border-radius: 3px;
}

.taskButtonOutlineStyle{
    color:  #0578BE;
    background: #FFFFFF;
    border: solid #0578BE 1px;
    border-color: #0578BE;
}

.buttonContainer{
    /* padding-left: 10px;
    padding-right: 10px; */
    padding-bottom: 10px;
}
.woText{
    /* margin-top: 15px; */
    font-family: "OpenSans-Regular";
    font-size: 16px;
    font-weight: 600;
}