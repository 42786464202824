@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.woinfosWrapper {
    padding-bottom: 0px;
    margin-top: 20px;
}

.woinfo {
    margin-bottom: 1.5rem;
    font-family: $OPENSANS_REGULAR;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
}

.moreButton {
    font-family: $OPENSANS_REGULAR;
    font-size: 14px;
    line-height: 18px;
    color:#0578be;
}
