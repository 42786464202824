.headerStyle{
  width:fit-content;
  padding-left: 5px
}
.headerTitle{
  margin-left: 20px;
  font-family: 'Barlow-SemiBold';
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  color: #003366;
}