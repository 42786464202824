@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.filterToogle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: $OPENSANS_REGULAR;
  font-size: 12px;
  line-height: 18px;
  color: $GRAY_1_075;
}

.filterToogle__text {
  display: flex;
  font-family: $OPENSANS_REGULAR;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  color: $BLUE_050;
  background: $GRAY_5_075;
  border: 1px solid $GRAY_4_050;
  border-radius: 0px 3px 3px 0px;
}

.filterToogle__textActive {
  color: $WHITE;
  background: $LIGHT_BLUE_100;
  border: 1px solid $LIGHT_BLUE_100;
}

.filterToogle__textDisabled {
  color: #999999;
  pointer-events: none;
  opacity: 0.3;
}
