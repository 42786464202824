@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.dropdownView{
    padding-top: 5px;
    padding-right: 2px;
    padding-bottom: 4px;
    /* box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25); */
    padding-left: 2px;
    margin-top: 5px;
    cursor: pointer;
    background-color: #FFFFFF;
    border: 1px solid #7F99B2;
    border-radius: 3.5px;
}

.dropdownViewWidth {
    position: relative;
    width:100%;
}


.listItem:hover{
    background-color: #DFF2FF;
}

.dropdownPickerViewWidth {
    position: relative;
    width: 100%;
}

.dropDownContainer{
    position: relative;
    width:inherit;
}

.textInput{
    width: inherit;
    background-color: $GRAY_5_100;
    border: none;
}
.ddvalueText{
    display: -webkit-box;
    padding-left: 10px;
    overflow: hidden;
    font-family: $OPENSANS_REGULAR;
    font-size: 14px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.ddvalueText.input {
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    cursor: pointer;
    background-color: transparent;
    border: none;
}
.dropdownList{ 
    /* position: absolute; */
   
    position: relative;
    max-height: 150px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* min-width: 200px; */
    overflow: auto;
    background-color: $WHITE;
}

.listItem{
    display: flex;
    max-width: inherit;
    /* padding-left: 10px; */
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    margin-bottom: 5px;
    margin-left: 2px;
    font-family: $OPENSANS_REGULAR;
    font-size: 14px;
    color:#192D38;
    cursor: pointer;
}

.listItem:hover{
   background-color: $GRAY_5_100;
}

.nonSelectable{
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.asterikRequired{
    color: #CC0000;
}

.italicClearText{
    font-family: "OpenSans-Italic";
}

.nameText{
    /* padding-left: 10px; */
    padding-top: 10px;
    font-family: $OPENSANS_REGULAR;
    font-size: 14px;
    color: $GRAY_1_075;
}
.labelText{
    font-family: "OpenSans-SemiBold";
    /* font-family: $OPENSANS_REGULAR; */
    font-size: 12px;
    font-weight: bold;
    color: $BLACK;
}

.pickerItemStyle{
    padding: 10;
    font-family: $OPENSANS_REGULAR;
    font-size: 14;
    color: "$BLACK"
}