@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.loginContainer{
    height: -webkit-fill-available;;
}
.gdHomeLogo{
    width: 230px;
    height: 230px;
}

.instroText{
    max-width: 500px;
    margin-top: 20px;
    font-family: $OPENSANS_REGULAR;
    font-size: 16px;
    line-height: 30px;
    color: $GRAY_1_075;
}

.buttonStyle{
    width: 100%;
    min-width: 400px;
    padding: 10px;
    color: $WHITE;
    background-color: $LIGHT_BLUE_100;
    border-width: 0px;
    border-radius: 2px;
    outline: transparent;
}

.errorText{
    color: $UI_BRIGHT_RED_100;
}
