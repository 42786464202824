@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";
@import "../../../constants/global-styles.scss";

.eIContainer{
    width: 100%;
    /* margin-top: 124px; */
}

.iFrameHeight{
    height: 100%;
    height: -moz-available;          /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    height: stretch;
    -webkit-overflow-scrolling: touch;
}
.contentFieldText{
    font-family: OpenSans-Regular;
    font-size: 14px;
    font-weight: 400;
    color: #53626A;
}

.attachmentLength{
    padding: 2px;
    padding-right: 4px;
    padding-left: 4px;
    font-size: 12px;
    border-color: #0578BE;
    border-style: solid;
    border-width: 1px;
    border-radius: 1px 
}
.contentFieldRes{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 14px;
    font-family: OpenSans-Regular;
    font-size: 10px;
    font-weight: 400;
    color: #53626A;
}

.attachmentLengthDisabled{
    padding: 2px;
    padding-right: 4px;
    padding-left: 4px;
    font-size: 12px;
    border-color: #CFD7DC;
    border-style: solid;
    border-width: 1px;
    border-radius: 1px 
}
.nameText{
    /* padding-left: 10px; */
    padding-top: 10px;
    font-family: $OPENSANS_REGULAR;
    font-size: 14px;
    color: $GRAY_1_075;
}
