@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.lineItem > p {
    margin: 0;
  }

.inspectionItem{
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 20px;
    cursor: pointer;
}

.lineItem_title{
    font-family: $OPENSANS_BOLD;
    font-size: 0.75rem;
    color: $BLACK;
}

.lineItem_details{
    font-family: $OPENSANS_REGULAR;
    font-size: 0.75rem;
    color: $GRAY_1_075;
}