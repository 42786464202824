.ag-theme-alpine {
    --ag-foreground-color: rgb(126, 46, 132);
    --ag-background-color: none !important;
    --ag-header-foreground-color: #dee2e6 !important;
    --ag-header-background-color: #003366 !important;
    --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
    --ag-font-size: 13px !important;
    --ag-font-family: 'OpenSans-SemiBold' !important;
    --ag-borders-row: none;
    --ag-row-border-style: solid ;
    --ag-row-border-width: 1px ;
    --ag-border-color: white !important;
    --ag-row-border-color: #e2e2e2 !important;
    --ag-cell-horizontal-border: 3px solid #e2e2e2 !important;
    /* width: auto !important; */
}
.ag-cell-label-container {
    display: flex;
    flex-direction: row !important;
    /* align-items: center; */
    gap: 5px;
    justify-content: center !important;
    width: 100%;
    height: 100%;
    padding: 5px 0px;
    overflow: hidden;
}

.border2 {
    width:100%;
    height: 32px;
    padding:5px;
    margin: 3px 0px;
    background-color: #FFF5CC;
    border:1px solid #E5A812;
    box-shadow: inset 0px 0px 5px rgb(225, 221, 131);
}
.border2 span{
    display: inline-block;
    width: 110px;
    padding: 0px 3px;
    margin: -10px 0 0 -5px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ag-ltr .ag-cell {
    text-align:center;
}
.ag-header-cell-label {
    flex:0 auto !important;
}

.ReactVirtualized__List {
    overflow: hidden !important;
}
.viewEntries {
    color:#0578BE;
    text-decoration: underline;
}
.viewEntries:hover {
    cursor: pointer;
}
.valueDescription {
    padding:0px 5px;
    margin-left:10px;
    color:#83929B;
    /* color:#BFCCD9; */
    /* font-family: 'Open-sans Regular'; */
    text-align:center;
    background-color: #F1F5F8;
    border:1px solid #BFCCD9;
    border-radius:3.5px

};
.materialTabs {
    font-family: 'OpenSans-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #7F99B2;
};
.custom-tooltip {
    width: 150px;
    height: 70px;
    overflow: hidden;
    border: 1px solid cornflowerblue;
}
.custom-tooltip p {
    margin: 5px;
    white-space: nowrap;
}
.ag-body {
    background-color: white !important;
}
.clearAll {
    display: flex;
    align-items: center;
    justify-content: center;
    color:red;
    text-decoration: underline;
}
.clearAll:hover {
    cursor: pointer;
}
.item1 {
    width: 40%;
    height: 31px;
    color: #0578BE;
    background-color: #ECF2F6;
}
.itemData {
    width: 60%;
    margin-top: 5px;
    font-family: 'OpenSans-Regular';
    font-size: 14px;
    font-weight: 400;
    color: #192D38;
}
.selectDropdown {
    width: 100%;
    height: 28px;
    padding: 0px;
    padding-left: 5px;
    font-size: 18px;
    background-color: white;
}
.inputBox {
    width: 95%;
    padding-top: 4px;
    padding-right: 5px;
    padding-bottom: 4px;
    padding-left: 7px;
    background-color: white;
    border: none;
    border-radius: 3.5px;
    box-shadow: inset 0px 0px 5px white;
}
select {
    background-image: url(../../../assets/images/new-dropdown-arrow.png);
    background-size: 14px;
}
.stores {
    font-family: "OpenSans-Regular";
    font-size: 12px;
    line-height: 18px;
    color: #1E6A0F;
}
.nonStores {
    font-family: 'OpenSans-Regular';
    font-size: 12px;
    line-height: 18px;
    color: #40668C;
}
.storesItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 32px;
    padding: 5px;
    margin: 3px 0px;
    font-size: 13px;
    color: #1E6A0F;
    background-color:#E0F0D1;
    border: 1px solid #31AF19;
    border-radius: 5px;
}
.nonStoresItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 32px;
    padding: 5px;
    margin: 3px 0px;
    font-size: 13px;
    color: #40668C;
    background-color:#F2F8FC;
    border: 1px solid #BFCCD9;
    border-radius: 5px;
}
.parent-chill{
    position: relative;
    display: block;
}
.optionsModal {
    position: absolute;
    z-index: 1000;
    width: 129px;
    height: 100px;
    background-color: yellow;
}
.menu-container {
    position: relative;
    left: 26px;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);
}
.menu-item :hover {
    color: #BFCCD9 !important;
}






