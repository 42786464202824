.app-container {
  /* padding-top: 100px !important; */
  overflow: scroll;
}

#root {
  height: 100%;
}

.page {
  display: table;
  width: 100%;
  height: 100%;
}

.cursor-pointer {cursor: pointer;}

.gerdauText {
  color: #003366;
}
.page-content {
  display: table-cell;
}
