@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.filterListHeader{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height:40px;
    padding: 0 0 0 8px;
    color:#40668C;
    background: #F2F8FC;
    border-radius: 3.5px 3.5px 0px 0px;
}
.filterListFooter{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:15px;
}

.headerText{
    font-family: "OpenSans-SemiBold";
    font-size: 12px;
    font-weight: bold;
    color: #192D38;
}
.filterListBody{
    padding:15px;
}
.clearAll{
    color: #BFC9D0;
    cursor:pointer;
}
.coloredClearAll{
    color:#C74A34;
    cursor:pointer;
}
.notification{
    display:flex;
    align-items: center;
    justify-content: center;
    width:25px;
    background: #F5F8FA;
    border: 1px solid #BFCCD9;
    border-radius: 3px;
}
.headerSide{
    display: flex;
    flex-direction: row;
    gap:3px;
}
.headerTitl{
    margin-left: 10px;
    font-size:18px;
    color:#40668C;
}
.filterListDiv{
    position:absolute;
    z-index:2;
    width:400px;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 0px 30px rgba(73, 89, 97, 0.8);

}
.selectedValue{
    display: flex;
    flex: none;
    flex-direction: row;
    flex-grow: 0;
    gap:3px;
    align-items: center;
    justify-content: center;
    order: 0;
    padding: 5px;
    border: 0.833px solid #40668C;
    border-radius: 2.5px;

}
.selectedItem{
    display: flex;
    flex: none;
    flex-grow: 0;
    align-items: center;
    order: 1;
    font-family: 'OpenSans-Regular';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    color: #40668C;
    text-align: center;
}
.close{
    width:15px;
    height:15px;
    margin-right:10px;
    cursor:pointer;
}
.closeItem{
    width:12px;
    height:12px;
    cursor:pointer
}
.operationContent__title {
    margin-bottom: 5px !important;
    font-family: $OPENSANS_BOLD;
    font-size: 12px !important;
    line-height: 18px;
    color: #000000;
  }


