@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.insSchedContainer{
    width: 100%;
}

.paddingLeft30 {
  padding-left: 30px;
}
.trashIconDiv{ 
  align-items: center;
  margin-right: 28px;
}

.introText {
  font-family: $OPENSANS_REGULAR;
  font-size: 20px;
  line-height: 30px;
  color: $GRAY_1_075;
}

.iconDiv{
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  cursor: pointer;
  
}
.noCursor {
  cursor: no-drop;
}
.cursor{
  cursor: pointer;
}

.dateText{
  margin: 0;
  margin-bottom: 10px;
  font-family: $OPENSANS_REGULAR;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  color: $GRAY_1_075;
}

.calendarContainer{
    height: 500px;
    padding-right: 30px;
    padding-left: 30px;
    overflow: scroll;
}

.footerContainer{
    position: sticky;
    bottom: 0px;
    display: inline-flex;
    align-items: baseline;
    float: right;
    width: 100%;
    padding: 15px;
    padding-right: 0px;
    padding-bottom: 10px;
    overflow-x: scroll;
    background-color: $WHITE;
}

.heading{
  font-family: $BARLOW_BOLD;
  color: $BLUE_100;
}

.collapseIcon{
  width: 22px;
  height: 13px;
}

.undoTextButton{
  margin-right: 30px;
  font-family: $OPENSANS_REGULAR;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  color: $LIGHT_BLUE_100;
  cursor: pointer;
}
.emptyBucket{
  position: absolute;
  width: 100%;
  height:calc(100% - 124px);
}
.undoTextButton__disabled {
  color: $GRAY_4_100;
  cursor: no-drop;
}

.calHeader{
    position: sticky;
    padding-right: 30px;
    padding-left: 30px;
    margin-top: 20px
}
 
.calendarHeader{
  margin-bottom: 10px;
  font-family: $OPENSANS_REGULAR;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: $GRAY_1_075;
}