@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.labeledField {
  display: flex;
  flex-direction: column;
}

.labeledField__field {
  margin-bottom: 12px;
  font-family: $OPENSANS_BOLD;
  font-size: 12px;
  font-weight: bold;
  color:#53626A;
}
