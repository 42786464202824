.attachment-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #bfccd9;
}

.attachment-text {
  flex-grow: 1;
  width: auto;
  margin-right: 10px;
  margin-left: 10px;
  overflow: hidden;
  font-family: OpenSans-Regular;
  font-size: 14px;
  text-overflow: ellipsis;
}
