@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.modal_header {
  position: 'sticky';
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 10px;
  font-family: Barlow-Bold;
  font-size: 20px;
  color: $WHITE;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px
}

.modalContainer{
  max-height: '100%';
  overflow-x: 'scroll';
  background-color: $WHITE;
  border-radius: 4px;
}

.closeButton{
  cursor: pointer;
}
.stickyFooter {
  position: sticky;
  bottom: 0px;
  padding: 0px;
  margin-bottom: 10px;
  background-color: #ffffff
}