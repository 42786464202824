.rs-picker-toggle-textbox{
  width: 95%;
  height: fit-content;
  padding-right: 0px;
  padding-left: 0px;
  margin-top: 4px;
  margin-right: 20px;
  margin-bottom: 4px;
  margin-left: 10px;
  color: #003366 !important;
}

.rs-picker-toggle{
  border: 1px solid #7F99B2 !important;
  border-radius: 3px !important;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25) !important;
}

.rs-picker-toggle-active{
  border: 1px solid #7F99B2 !important;
  border-radius: 3px !important;
  box-shadow: inset 0px 0px 5px #0578BE !important;
}

.rs-picker-toggle-wrapper{
  width: 100% !important;
}

.rs-picker-toggle-value{
  color: #192D38 !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content{
  background-color: #003366 !important;
}

.rs-btn-primary{
  background-color: #003366 !important;
}