@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";
@import "../../../constants/global-styles.scss";

.woContainer {
    width: 100%;
    min-height: calc(100% - 124px);
    /* margin-top: 124px; */
}

.field {
    width: 100%;
    background: $GRAY_5_100;
    border: 0;
    border-radius: 5px;
  }

.saveButton {
    padding-top: 14px;
    padding-right: 30px;
    padding-bottom: 14px;
    padding-left: 30px;
    font-family: $OPENSANS_REGULAR;
    font-size: 14px;
    border-radius: 2px;
}

.kittingContainer {
    margin-top: 15px;
}

.kittingButton {
    display: flex;
    align-items: center;
    margin-top: 18px;
}

.immedExecContainer {
    display: flex;
    align-items: center;
    margin-top: 18px;
}

.titleText {
    margin-bottom: 10px;
    font-family: $BARLOW_BOLD;
    font-size: 24px;
    font-weight: 700;
    color: rgba(0, 51, 102, 1);
}

.disabledSaved {
    color: $GRAY_1_050;
    background-color: $GRAY_3_025;
}

.enabledSaved {
    color: $WHITE;
    cursor: pointer;
    background-color: $LIGHT_BLUE_100;
}
.infoIcon {
    width: 10px;
    height: 10px;
    vertical-align: baseline;
}

.infoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
}

.addRowContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    cursor: pointer;
}

.addView{
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
}

.reserveMaterialContainer {
    width: 100%;
    margin-right: 20px;
}

.sideHeading {
    margin-bottom: 10px;
    font-family: $BARLOW_BOLD;
    font-size: 24px;
    font-weight: 700;
    color: $GRAY_1_075;
}
.kittingText {
    margin-left: 10px;
    font-family: $OPENSANS_REGULAR;
    font-size: 14px;
    font-weight: 400;
    line-height: 10px;
    color: "$BLACK";
}
.woContainer{
    width: 100%;
    min-height: calc(100% - 124px);
    transition: all 0.6s ease;
    /* margin-top: 124px; */
}

.saveButton{
    padding-top: 14px;
    padding-right: 30px;
    padding-bottom: 14px;
    padding-left: 30px;
    font-family: "OpenSans-Regular";
    font-size: 14px;
    border-radius: 2px;
}

.opHeading{
    font-family: "OpenSans-SemiBold";
    font-size: 14px;
    font-weight: 700;
    color:#53626A;
}

.asterikRequired{
    color: #CC0000;
}

.contentFieldSmall {
    display: flex;
    align-items: center;
    height: 12px;
    font-size: 8px !important;
}

.attachmentLength{
    padding: 2px;
    padding-right: 4px;
    padding-left: 4px;
    font-size: 12px;
    border-color: #0578BE;
    border-style: solid;
    border-width: 1px;
    border-radius: 1px 
}

.attachmentLengthDisabled{
    padding: 2px;
    padding-right: 4px;
    padding-left: 4px;
    font-size: 12px;
    border-color: #CFD7DC;
    border-style: solid;
    border-width: 1px;
    border-radius: 1px 
}
.contentFieldRes{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 14px;
    font-family: OpenSans-Regular;
    font-size: 10px;
    font-weight: 400;
    color: #53626A;
}
.contentFieldBold{
    margin-right: 5px;
    font-family: "OpenSans-SemiBold";
    font-size: 10px;
    font-weight: 700;
    color:#53626A;
}


.titleText{
    margin-bottom: 10px;
    font-family: "Barlow-Bold";
    font-size: 24px;
    font-weight: 700;
    color:rgba(0, 51, 102, 1);
}

.disabledSaved{
    color: #8C969B;
    background-color: #E0E4E6;
}

.footerEdit{
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    z-index: 1000;
    padding: 10px;
    background-color: white;
}

.enabledSaved{
    color: white;
    cursor: pointer;
    background-color: #0578BE;
}
.infoIcon{
    width: 10px;
    height: 10px;
    vertical-align: baseline;
}
.sideHeading{
    margin-bottom: 10px;
    font-family: "Barlow-Bold";
    font-size: 24px;
    font-weight: 700;
    color:#53626A;
}
.labelTextStyle{
    margin-bottom: 5px !important;
    font-family: "OpenSans-SemiBold";
    /* font-family: $OPENSANS_REGULAR; */
    font-size: 12px;
    font-weight: bold;
    color: $BLACK; 
}
.table{
    width: calc(100% - 10px) !important;
    margin: 10px;
    font-size: 14px;
    text-align: center;
    border-spacing: 0px;
    background-color: #F2F8FC;
    border:1px solid #BFC9D0;
    border-right:none;
    border-bottom: none;
}
tr th{
    flex-basis: 100%;
    height: 33px;
    padding-left: 3px;
    font-family: 'OpenSans-Regular';
    font-style: normal;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    background: #003366;
    border-right: 1px solid #BFC9D0;
}
.lightblue{
    display:table-row;
    background: #FFFFFF;
}
.white{
    background:#F2F8FC;
}
tr td{
    flex-basis: 100%;
    height: 33px;
    padding-left: 3px;
    font-family: 'OpenSans-Regular';
    font-size:14px;
    font-style: normal;
    font-weight: 400;
    color: #192D38;
    text-align: center;
    border-right: 1px solid #BFC9D0;
    border-bottom: 3px solid #BFC9D0;
}
tr th:last-child,tr td:last-child{
    border-right: none;
}
.confDetailsType{
    padding:5px;
    margin:5px;
    margin-right:5px;
    font-family: 'OpenSans-Regular';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color:#778389;
    background:#F5F8FA;
    border:1px solid #DFE4E7;
}
