@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";
@import "../../../constants/global-styles.scss";

.headerSticky {
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    z-index: 1000;
    padding-bottom: 10px;
    background-color: white;
}

.headerTitle {
    margin-left: 20px;
    font-family: 'Barlow-SemiBold';
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color: #003366;
}

.contentFieldText {
    font-family: OpenSans-Regular;
    font-size: 14px;
    font-weight: 400;
    color: #53626A;
}

.woinfosWrapper {
    padding-bottom: 0px;
}

.woinfosWrapper {
    margin-top: 20px;
}

.submitModal__footer {
    flex-wrap: wrap;
    justify-content: flex-end;
}

.modal__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px 20px;
}

.modal__content {
    padding: 20px;
}

.personnelItem {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.personnelRow_icon {
    display: flex;
    flex-basis: 62px;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 3px;
}

.opHeading {
    font-family: "OpenSans-SemiBold";
    font-size: 14px;
    font-weight: 700;
    color: #53626A;
}
.dropdownView{
    padding-top: 5px;
    padding-right: 2px;
    padding-bottom: 4px;
    /* box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25); */
    padding-left: 2px;
    margin-top: 5px;
    cursor: pointer;
    background-color: #FFFFFF;
    border: 1px solid #7F99B2;
    border-radius: 3.5px;
}
.clockField {
    padding: 14px 10px;
    padding-top: 6px;
    padding-bottom: 5.2px;
    padding-left: 5px;
    font-family: $OPENSANS_REGULAR;
    font-size: 14px !important;
    color: $BLACK;
    background: $GRAY_5_100;
    background-color: #ffffff;
    background-image: url(../../../assets/images/icon_clock.png);
    background-repeat: no-repeat;
    background-position: center right 10px;
    border: 1px solid #7F99B2;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);
    
  }
  .clockField:hover {
    color: $BLACK;
    text-decoration: none;
  }
  .textStyle {
    font-family: $OPENSANS_REGULAR;
    font-size: 14px !important;
  }