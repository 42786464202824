@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.singleOperation {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  min-height: 90px;
  padding: 10px 0px;
  padding-left: 16px;
  font-family: $OPENSANS_REGULAR;
  font-size: 12px;
  line-height: 18px;
  color: $GRAY_1_075;
  cursor: pointer;
  border-bottom: 1px solid $GRAY_3_050;
  transition: all 300ms;
}

.singleOperation:hover {
  background-color: rgb(252, 252, 252);
  transition: all 600ms;
  transition-delay: 300ms;
}
