.subheaderContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: #F1F5F8;
    border: 1px solid #CFD7DC;
}

.subHeaderContent{
    font-family: 'Barlow-SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: #53626A;
}

.headerCol{
    display: flex;
    flex-direction: row;
}

.headerIconDiv{
    margin-right: 10px;
    margin-left: 15px;
}

.arrowDiv{
    margin-right: 15px;
}

.arrowIcon{
    margin-bottom: 4px;
}

@keyframes inAnimation {
    0% {
      visibility: hidden;
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
  
  @keyframes outAnimation {
    0% {
      opacity: 1;
    }
    100% {
      visibility: hidden;
      opacity: 0;
    }
  }
  