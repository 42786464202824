@import "../../../constants/global-styles.scss";
@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.woinfosWrapper {
    padding-bottom: 0px;
  }

.woinfosWrapper {
    margin-top: 20px;
}

.operationsSingularwrapper {
  font-size: 12px;
  border-bottom: 1px solid $GRAY_3_050;
}
.operationsSingulardescription {
  font-family: $OPENSANS_BOLD;
}
.selectOpHeader{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}