@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.tabs {
}

.tabs > .tabs_header {
  flex-basis: 58px;
  flex-shrink: 0;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 58px;
  margin: 0;
  font-family: $OPENSANS_REGULAR;
  font-size: 20px;
  line-height: 20px;
  color: $GRAY_1_050;
  background: $GRAY_5_075;
}

.tab_active {
  color: $LIGHT_BLUE_100;
  background: $WHITE;
}
