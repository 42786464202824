@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.sectionHeader{
    display: inline-flex;
    align-items: center; 
    width: 100%;
    height: fit-content;
    cursor: pointer;
    background-color: $BLUE_100;
}
.sectionText{
    display: flex;
    width: 100%;
    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0;
    margin-left: 20px;
    font-family: $BARLOW_SEMI_BOLD;
    font-size: 1.25rem;
    color: $WHITE;
}
.imageStyle{
    height: 12px;
    margin-right: 30px;
}