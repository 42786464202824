.formHeaderMaterial {
    font-family: 'OpenSans-Bold';
    font-weight: 600;
    color:#003366;
}

.formHeaderMaterialCount {
    padding-top: 5px !important;
    padding-right: 7px !important;
    padding-bottom: 5px !important;
    padding-left: 7px !important;
    font-family: 'OpenSans-Regular';
    font-size: 12px;
    font-weight: 600;
    color: #003366;
    border: 1px solid #7F99B2;
    border-radius: 4px;
}

.formHeaderMaterialDes {
    font-family: 'OpenSans-Regular';
    color:#003366;
}

.sparesTable{
    min-width: 900px;
    overflow: scroll;
    word-break: break-word;
}
.labelTextStyle{
    margin-bottom: 5px !important;
    font-family: "OpenSans-SemiBold";
    /* font-family: $OPENSANS_REGULAR; */
    font-size: 12px;
    font-weight: bold;
    color: black; 
}

.sparestableHeader {
    position: sticky;
    top: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: OpenSans-SemiBold;
    font-size: 14px;
    font-weight: 400;
    color: #40668C;
    background-color: #FFF5CC;
}

.sparesTableHead{
    position: sticky;
    top: 100px;

}

.filterContainer{
    background-color: #F2F8FC;
}

.sidebarContainer {
    width: 20px;
    margin-right: 10px;
    transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  }

.filterHeading{
    font-family: OpenSans-SemiBold;
    font-size: 18px;
    font-weight: 400;
    color: #003366;

}
  
.sidebar {
    min-width: 250px;
    max-width: 250px;
    height: 100%;
    min-height: calc(100vh - 53px);
    padding-top: 26px;
    background-color: #fafcfd;
    filter: drop-shadow(1px 0 4px rgba(0, 0, 0, 0.5));
    transition: all 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}
  
.sticky {
    width: 250px;
}
  
.retracted {
    transform: translateX(-220px);
}