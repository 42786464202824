@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.attachmentContainer {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    overflow: scroll;
}
.addAttachment {
    width: 130px;
    height: 65%;
    margin-top: 5px;
    margin-left: 10px;
}
#file-input {
    visibility: hidden;
}
.newAttachmentText {
    display: -webkit-box;
    margin-top: 15px;
    margin-right: 5px;
    margin-left: 5px;
    overflow: hidden;
    font-family: $OPENSANS_REGULAR;
    font-size: 14px;
    font-weight: 400;
    color: $GRAY_1_075;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.sizeText {
    font-family: $OPENSANS_REGULAR;
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    color: $GRAY_1_075;
}

.attachmentName {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0px;
}
.errorText {
    margin-left: 5px;
    font-family: $OPENSANS_REGULAR;
    font-size: 16px;
    font-weight: 400;
    color: $UI_DARK_RED_100;
    vertical-align: bottom;
}

.labelStyle {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.imageStyle {
    margin-top: 35%;
    margin-bottom: 35%;
    margin-left: 35%;
}
