@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.customButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  font-family:  $OPENSANS_REGULAR;
  font-size: 14px;
  color: white;
  background-color: $LIGHT_BLUE_100;
  border-radius: 3px;
}
.disabledButton {
  color: $GRAY_1_050 !important;
  background-color: $GRAY_3_025 !important;
  border: 1px solid $GRAY_4_050 !important;
  border-radius: 3px;
}
.customButton:disabled {
  color: #BFC9D0 !important;
  background-color: #F1F5F8 !important;
  border: 1px solid #BFC9D0 !important;
  border-radius: 3px;
}

.outlineButton {
  color: $LIGHT_BLUE_100;
  background-color: $WHITE;
  border: 1px solid $LIGHT_BLUE_100;
  border-radius: 3px;
}

