@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.textInputContainer{
    width: inherit;
    padding-top: 4px;
    padding-right: 5px;
    padding-bottom: 4px;
    padding-left: 7px;
    background-color: $GRAY_5_100;
    border: 1px solid #7F99B2;
    border-radius: 3.5px;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.capitaliseText > input, textarea{
    text-transform: uppercase;
}
.asterikRequired{
    color: #CC0000;
}

.textInput{
    width: 100%;
    font-family: $OPENSANS_REGULAR;
    font-size: 14px;
    background-color: $GRAY_5_100;
    border-color: transparent;
    border-width: 0px;
}

.textInput::placeholder{
    font-family: OpenSans-Italic;
    color:#40668C;
    text-transform: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.labelTextStyle{
    margin-bottom: 5px !important;
    font-family: "OpenSans-SemiBold";
    /* font-family: $OPENSANS_REGULAR; */
    font-size: 12px;
    font-weight: bold;
    color: $BLACK; 
}
.italicText{
    font-family: $OPENSANS_ITALIC;
    font-size: 12px;
    font-weight: bold;
    color: $BLACK;
}
.nameText{
    margin-top: 10px;
    font-family: $OPENSANS_REGULAR;
    font-size: 14px;
    color: $GRAY_1_075;
    /* padding-left: 10px; */
}
/* .upperCaseTextArea { text-transform: uppercase; } */