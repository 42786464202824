@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.emptyScreen{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 124px);
    font-family: $OPENSANS_REGULAR;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    color: $GRAY_1_075;
  }
  
  .emptyScreen span {
    margin-top: 20px;
  }