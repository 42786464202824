.subheaderContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    // background-color: #F1F5F8;
    border: 1px solid #CFD7DC;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}
.subheaderContainer1{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  // background-color: #F1F5F8;
  border: 1px solid #CFD7DC;
  border-radius:6px;
}

.subHeaderContent{
    font-family: 'Barlow-SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: #53626A;
    border-bottom-right-radius:5px;
    border-bottom-left-radius:5px;
}

.headerCol{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}

.headerIconDiv{
    margin-right: 10px;
    margin-left: 15px;
}

.arrowDiv{
    display: flex;
    justify-content: right;
    margin-right: 15px;
}

.arrowIcon{
    margin-bottom: 4px;
}
.childrenStyle {
    padding:10px;
    margin-top:-10px;
    border:1px solid  #CFD7DC;
    border-top:none;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
};

@keyframes inAnimation {
    0% {
      visibility: hidden;
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
  
  @keyframes outAnimation {
    0% {
      opacity: 1;
    }
    100% {
      visibility: hidden;
      opacity: 0;
    }
  }
.headerDescription {
    padding:0px 5px;
    margin-left:10px;
    border:1px solid #CFD7DC;
}
.borderBottomStyle {
    border-bottom-right-radius: '5px';
    border-bottom-left-radius: '5px'
}
