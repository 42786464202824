.kittingText {
    position:absolute;
    top: 6px;
    left: 50px;
    font-family: "OpenSans-SemiBold";
    /* font-family: $OPENSANS_REGULAR; */
    font-size: 12px;
    font-weight: bold;
    color: black;
}
.headerDescription {
    padding:0px 5px;
    margin-left:10px;
    color:#83929B;
    text-align:center;
    /* margin-bottom:2px; */
    background-color: #F1F5F8;
    border:1px solid #C74A34;
    border-radius:3.5px
}
.valueDescription {
    padding:0px 5px;
    margin-left:10px;
    color:#83929B;
    /* color:#BFCCD9; */
    /* font-family: 'Open-sans Regular'; */
    text-align:center;
    background-color: #F1F5F8;
    border:1px solid #BFCCD9;
    border-radius:3.5px

};
.dashedBorder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // width:98% !important;
    height:132px;
    padding: 16px 0px;
    margin:15px;
    text-align:center;
    background-image: url('../../../assets/images/icon.svg');
    background-repeat: no-repeat;
    background-position: center center;
    border:1px dashed #7F99B2;
    border-radius: 4px;
}
.viewEntries {
    color:#0578BE;
    text-decoration: underline;
}
.viewEntries:hover {
    cursor: pointer;
}
.noShowFile {
    width:100%;
    height:100%;
    padding:10px;
    border: 1px solid black;
    opacity:0;
}
.uploadText {
    margin-top: 20px;
    font-family: "OpenSans-Regular";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color:#192D38;
}
.labelText {
    font-family: "OpenSans-SemiBold";
    /* font-family: $OPENSANS_REGULAR; */
    font-size: 12px;
    font-weight: bold;
    color: black;
}
.selectedFile {
    width:max-content;
    padding:5px 5px;
    color:#83929B;
    background-color: #F1F5F8;
    border: 1px solid #BFCCD9;
}
.deleteText {
    font-family: "OpenSans-Regular";
    font-weight: 400;
    color:#53626A;
    text-align: center;
}
.materialTabs {
    font-family: 'OpenSans-Regular';
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    color: #7F99B2;
    cursor: pointer;
};
.materialActiveTab {
    padding-bottom: 5px;
    font-family: 'OpenSans-Regular';
    font-size: 15px;
    font-weight: 400;
    // margin-top: 2px;
    line-height: 18px;
    color:#0578BE;
    cursor: pointer;
    border-bottom: 2px solid #0578BE;
}
.headerText {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #53626A;

}
.asterikRequired{
    color: #CC0000;
}


.formHeaderMaterial {
    font-family: 'OpenSans-Bold';
    font-weight: 600;
    color:#003366;
}

.formHeaderMaterialCount {
    padding-top: 5px !important;
    padding-right: 7px !important;
    padding-bottom: 5px !important;
    padding-left: 7px !important;
    font-family: 'OpenSans-Regular';
    font-size: 12px;
    font-weight: 600;
    color: #003366;
    border: 1px solid #7F99B2;
    border-radius: 4px;
}

.formHeaderMaterialDes {
    font-family: 'OpenSans-Regular';
    color:#003366;
}

.sparesTable{
    min-width: 900px;
    overflow: scroll;
    word-break: break-word;
}
.labelTextStyle{
    margin-bottom: 5px !important;
    font-family: "OpenSans-SemiBold";
    font-size: 12px;
    font-weight: bold;
    color: black;
}

.sparestableHeader {
    position: sticky;
    top: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: 'OpenSans-SemiBold';
    font-size: 14px;
    font-weight: 400;
    color: #40668C;
    background-color: #FFF5CC;
}

.sparesTableHead{
    position: sticky;
    top: 100px;

}

.filterContainer{
    background-color: #F2F8FC;
}

.sidebarContainer {
    width: 20px;
    margin-right: 10px;
    transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  }

.filterHeading{
    font-family: 'OpenSans-SemiBold';
    font-size: 18px;
    font-weight: 400;
    color: #003366;

}
  
.sidebar {
    min-width: 250px;
    max-width: 250px;
    height: 100%;
    min-height: calc(100vh - 53px);
    padding-top: 26px;
    background-color: #fafcfd;
    filter: drop-shadow(1px 0 4px rgba(0, 0, 0, 0.5));
    transition: all 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}
  
.sticky {
    width: 250px;
}
  
.retracted {
    transform: translateX(-220px);
}
.successText {
    font-family: 'OpenSans-SemiBold';
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: #192D38;
}
.externalRepairText {
    font-family: 'OpenSans-Regular';
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    color: #192D38;
    text-align: center;
}
.loaderMessage {
    margin-top: 20px;
    font-family: "OpenSans-Regular";
    font-size: 24px;
    font-weight: 400;
    line-height: 10px;
    color: #192D38;
}
.modalLoader {
    position: absolute;
    z-index: 100000;
    width: 80%;
    height: 42%;
}
.loaderLastMessage {
    font-family: "OpenSans-Regular";
    font-size: 14px;
    font-weight: 400;
    color: #53626A;
}
.failedTransaction {
    width: 105px;
    height: 18px;
    margin-left: 15px;
    font-size: 10px;
    color: #CC0000;
    text-align: center;
    background-color: #FFE2DC;
    border : 1px solid #CC0000;
    border-radius: 2px;
}
.successTransaction {
    width: 150px;
    height: 18px;
    margin-left: 15px;
    font-size: 10px;
    color: #31AF19;
    text-align: center;
    background-color: #E0F0D1;
    border : 1px solid #31AF19;
    border-radius: 2px;
}
.charactersRemaining {
    font-family: "OpenSans-Regular";
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #53626A;
}
.vendorText {
    font-family: 'OpenSans-Regular';
    line-height: 18px;
    color:  #1E6A0F;
}
.vendorFailedText {
    font-family: 'OpenSans-Regular';
    line-height: 18px;
    color:  #990000;
}
.stockText {
    font-size: 14px;
    color: #53626A;
}