@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.clipContainer {
  position: relative;
  margin-left: 10px;
  cursor: pointer;
}

.clipNumber {
  position: absolute;
  right: 0;
  bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13px;
  height: 13px;
  font-size: 10px;
  color: $WHITE;
  background-color: $RED_100;
  border-radius: 50%;
}
