@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.bomListHeading {
    box-sizing: border-box;
    padding: 4px;
    margin: 2px;
    margin-right: 15px;
    font-family: $OPENSANS_SEMI_BOLD;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    color: $GRAY_2_075;
    background-color: $GRAY_5_050;
    border: 1px solid $GRAY_4_050;
    border-radius: 2px;
}

.bomList {
    margin-right: 10px;
    font-family: "OpenSans-Regular";
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    color: #53626A;
}
.workOrderTileContainer {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    cursor: pointer;
    border-color: #C1C8CD;
    border-bottom-width: 1px;
}
.descriptionText {
    margin: 0;
    margin-bottom: 5px;
    font-family: 'OpenSans-Regular';
    font-size: 12px;
    font-weight: 600;
    color: #192D38
}

.materialText {
    margin: 0px;
    margin-right: 0.3rem !important;
    margin-bottom: 5px;
    font-family: 'OpenSans-Bold';
    font-size: 12px;
    font-weight: 600;
    color: #003366;
}