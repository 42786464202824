@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.modalView {
    position: relative;
    width: 70%;
    max-width: 700px;
    height: 80%;
    background-color: $WHITE;
    border-radius: 4px;
}
.feedbackHeader {
    display: flex;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left:263px;
    background-color: $LIGHT_BLUE_100;
    border-radius: 4px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.feedbackHeaderText {
    flex: 2;
    font-family: $BARLOW_SEMI_BOLD;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: $WHITE;
}

.feedbackContainer {
    height: 78%;
}

.attachmentDiv {
    height: 95%;
}

.attachmentContainer {
    height: 35%;
}
.instText {
    height: 5%;
    margin-left: 10px;
    font-family: $OPENSANS_BOLD;
    font-size: 12px;
    font-weight: bolder;
    line-height: 12px;
    color: $BLACK;
}

.feedbackTitle {
    height: 10%;
    margin-left: 10px;
    font-family: $BARLOW_BOLD;
    font-size: 20px;
    font-weight: bolder;
    line-height: 24px;
    color: rgba(0, 51, 102, 1);
}
.feedbackText {
    width: calc(100% - 20px);
    height: 90%;
    padding-top: 9px;
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
    padding-left: 10px;
    margin-right: 10px;
    margin-left: 10px;
    font-family: $OPENSANS_REGULAR;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    resize: none;
    background-color: $GRAY_5_100;
    border-width: 0px;
    border-radius: 5px;
}

.submitButton {
    position: absolute;
    right: 10px;
    bottom: 0px;
    bottom: 10px;
}
.addAttachment {
    width: 20%;
    height: 65%;
    margin-left: 10px;
    /* margin-right: 10px; */
}
#file-input {
    visibility: hidden;
}
.closeIcon {
    position: absolute;
    right: 10px;
    display: inline-block;
    flex: 1;
    vertical-align: middle;
    cursor: pointer;
}
.titleContainer {
    height: 35%;
}
.newAttachmentText {
    display: -webkit-box;
    margin-top: 15px;
    margin-right: 5px;
    margin-left: 5px;
    overflow: hidden;
    font-family: $OPENSANS_REGULAR;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 14px; */
    color: rgba(83, 98, 106, 1);
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.sizeText {
    font-family: $OPENSANS_REGULAR;
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    color: rgba(83, 98, 106, 1);
}
.errorText {
    margin-left: 5px;
    font-family: $OPENSANS_REGULAR;
    font-size: 16px;
    font-weight: 400;
    color: rgba(153, 0, 0, 1);
    vertical-align: bottom;
}
