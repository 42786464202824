@import "../../../constants/scss-constants/colors.scss";
@import "../../../constants/scss-constants/fonts.scss";

.labeledField {
  display: flex;
  flex-direction: column;
}

.labeledField__wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 16px;
  border-bottom: 2px solid $WHITE;
}

.labeledField__field {
  margin-bottom: 12px;
  font-family: $OPENSANS_BOLD;
  font-size: 12px;
  font-weight: bold;
}

.operationsSubtitle {
  font-family: Barlow-Bold;
  font-size: 20px;
  color: $WHITE;
  background: $BLUE_100;
}
